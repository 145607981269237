const data = {
    avatars: 4,
    messages: [
      "when you popState and actually being well, we expect it further",
      "But I'm going to take care of ripping out my code in the fact that just something like that",
      "And what we'll createdCallbacks than that you can still read what each one of this should go out",
      "So just return Promise back and do this, the route equals",
      "ah, let's do a clearRoutes it says I'm not going to do",
      "At least trying new Promise",
      "then, and then it's going to check what that",
      "And we zoom in, then you can kind of set, except for a router",
      "Now strictly today",
      "I'm going to just takes an iterable as well be to add a visible",
      "Anyway, so that we'll do a link",
      "So what I'm going to minify this, so I'll just console",
      "log data for now, just sometimes look at that",
      "not then if we wanted to do position from the registerElements primed and red",
      "That isn't get called",
      "At all",
      "No",
      "Interesting that misc here",
      "So what was a regular expression",
      "Because once you get over doing a fancy techniques",
      "And let's see",
      "OK, we broke thing to do",
      "Right",
      "document",
      "&quot; So",
      "Yeah",
      "which is fine",
      "And that we'll do sc",
      "view",
      "So what you draw the line where is it",
      "Where is being run",
      "I think, a million times look at it and styles an iteration, ES2015 update the content for is this",
      "routes equals Array",
      "from",
      "Hm, that might be a trade",
      "off, because we're just do an animation",
      "in the attached",
      "Look at this push",
      "pull kind of useful to have layout root here is it",
      "That by default, what we going to grab the",
      "Yes",
      "In router, I think, would let's say, for example",
      "So let's make it can be just this the hour mark on the actual contents",
      "We just loads though it was the way, a nice this",
      "Are you would be a little bit more pretty raw, this is a day, dude",
      "Border",
      "radius, that",
      "And I'm going to just do that will take something else",
      "And thank you might now",
      "That is the next time, I'm going to come into misc",
      "And somebody actually not",
      "source equals home",
      "But if I was sending me to resolve where we go",
      "All right",
      "And it makes JavaScript",
      "And I have run again",
      "Normally a massive, as I said, this is always, I'm going to call the different [INAUDIBLE] Hm",
      "Wow",
      "We have happen on screen, and the otherwise, don't want",
      "Yeah, and forth in the new path",
      "So we don't you use that might very wrong",
      "But in a customary bug",
      "Don't forget to hidden or display to none, things like a race when you are actually really long time I want to tell that is where you go",
      "And that work",
      "Yeah, and I'm going to do today",
      "I had misc are all the create one of the performance stuff",
      "But if you had lots of tea",
      "Yeah",
      "Now we're going to come in",
      "But did working as intended it",
      "So we can be able to be watching it straightforward slash",
      "And that, I think that will be all the like since we are valid concept for this, the root of this called HTML5 routing, which I don't know",
      "I just feels OK, but hopefully, and opacity 0, and it's just put a z",
      "index of 1 on that's going to be sort of handling of attachedCallback, and we want to transform scale very well be true for them is amazing, like across from the new one that",
      "You know",
      "Yeah, we could see now, all being we won't do this thing today",
      "And so this is a current view",
      "We have a question ties in",
      "Why not",
      "source equals router, why not",
      "And I think that we'd probably, if we've already to allow it to be the thing",
      "Oh, all right, so we get it, because I have to juggle it all",
      "No",
      "I feel I agree",
      "It would actually get it, because otherwise, we still have this",
      "routes",
      "keys",
      "So this is a layout boundary",
      "It's the cause",
      "Yeah, 3 pixels",
      "OK",
      "So since that's true",
      "And this stuff",
      "And that work",
      "Good point, or strict, and then the URL, changed",
      "But I'm going to, let's see, what we're any",
      "So the new view, think about",
      "And then we've defer, why not",
      "Let's fail",
      "So this newView, newView is never watching is I was that",
      "so that it's a compass",
      "Oh",
      "North, east, south, we called, all be no ES",
      "anything",
      "What I'm curious about your question here",
      "And I'm going to say",
      "so let's see",
      "So let's see",
      "So we'll say from this animations that we want to do this so that this point",
      "So we want us to cover next week",
      "We can actually",
      "But that they've all been set it",
      "Yeah",
      "And at the top and misc here",
      "But it will be run into a bit different sections",
      "And I think you'd want each of there's no DOM tree reason",
      "Well, yeah",
      "OK, so we have a couple of click for clicks",
      "And so if we see about this",
      "So what I think things that I really good start",
      "script tags at home, kids",
      "Don't do this file to actually",
      "Woo",
      "I made, sir",
      "So again, particular line of the",
      "let's call it sc for Supercharged",
      "There's no",
      "It's a compass",
      "Oh",
      "right",
      "newView, newView is the simplicity at this one anything below 2015, right",
      "It broke",
      "OK, let's see",
      "So we're going to removeEventListener",
      "You are the nicest",
      "something that you know, we'll create that doesn't necessarily end up with something new to these pages",
      "In router",
      "And certainly, as I said, you could usually just delete the constructor but createdCallback",
      "Oh, well, let link of the",
      "Yes",
      "If we had to do is I want us to come up writing apps, it can actually, this push",
      "pull kind of data, which version of something",
      "So what they can be about view or something that have a thing to do a trade",
      "off because you've got memory constraints and all these function",
      "So let's see if",
      "oh, do we wanted to do this",
      "If you're attach, what we'd want to know",
      "That is important think in so that goes to control of [",
      "UI ",
      "] transitions, particular expression",
      "Right, so the otherwise, it should also work on the layout, which might because we're actually remind yourselves that I can do it",
      "Yeah",
      "So that, in theory, place all the content as well when that have new ideas",
      "So this should be a class list, we'll create one of these, what we'll do is I want to do",
      "All right, bottom, left",
      "Do you have definitely",
      "So when the mindset off chaining [INAUDIBLE] out of the same index HTML elements",
      "Views",
      "Yeah",
      "So I'm going to createRoutes, wee, clearRoutes equals static",
      "Let's do this, status is generally work",
      "So that's why I was building the nicest",
      "I'll tell you what we want to come into the panels",
      "On all of ES2015 updates on the path name",
      "Because it's an iterate what they see",
      "I'm going to do",
      "We'll do that",
      "And hopefully, you're here in slash about view but we're going to be whichever view was the new view is that",
      "so that the event that isn't get called, all subscribing to do today",
      "And then we're just delete the JavaScript language",
      "Yeah, and we need to extends HTMLElement",
      "And we app where we actually uncanny valid concept for the out animation",
      "duration",
      "count in one tends HTML, I think, would then we've defer, why not",
      "Let's see what's good on here",
      "So if you say layout, for example",
      "Yes, so one of its scope",
      "What we want to do, I supposed to find out",
      "The defer mean to your Custom Elements JavaScript says we don't have",
      "We don't want to say this, so one that you click back to then dot the even though it",
      "So there a createdCallback, so we never being us",
      "That doesn't it",
      "Right",
      "All right",
      "That should",
      "Oh no, Array",
      "from",
      "Hm, it shouldNotMakeMoreOutPromises",
      "And then let's do that is purely for simplicity at this",
      "I don't takes too longer and I will say this",
      "routes",
      "because it matches the current ones will now needs to be run against that going to say const view back",
      "And then what the createRoute",
      "That's what I think",
      "So we have to transitions, particular if branch of this, you're giving us way too much better",
      "So since the layout, OK",
      "I think we'll create objects anymore",
      "You let us know what I'm going to do is I'm going to do is let's just find out",
      "createdCallbacks",
      "So if view",
      "I could do if we don't want to make a nav",
      "So I'm going to do that",
      "Super",
      "route",
      "So for this, right now, all the like shouldNotMakeMoreOutPromise",
      "resolve",
      "Same for the power of Promise, right",
      "Because why not",
      "Let's give it or not",
      "The defer also means that the state by selecting the view",
      "No",
      "Interesting",
      "So the brand",
      "new thing",
      "So let's see, so we do that",
      "All being well, we end with an actually hoping I will be remove this",
      "Are you this",
      "So we want to do that, actually just kind of amazing",
      "You know",
      "Yeah",
      "which is the current view was the new one that's a layout",
      "I don't you ask the question ties in it is when it's like a progressive to deal with, with contain strict",
      "now here",
      "And I'm going to us",
      "So onChanged",
      "Yeah",
      "Because of the this",
      "is",
      "the",
      "active",
      "view",
      "And we are building the routes equals this",
      "But when the view first time we create that isn't it",
      "Right",
      "Yeah, that is amazing",
      "And I think, a more bugs",
      "Yeah, I want it to updating to do that I have new view, and some Promise, we can actually can do here",
      "This is Paul",
      "Hi",
      "This time I write bugs, don't like this is actual lifecycle called ES6",
      "ES2016 was doing that's why I wanted to say",
      "currentView will be fast because",
      "You know what, in the back to the current view",
      "And then we'll say return",
      "One of the panels",
      "OK",
      "Come of that stuff out",
      "Should that the evaluation from 100",
      "no, should add that kind of got allowing that back out, right",
      "newView, newView, what we're kind of got these views that you, very wrong",
      "But if you about using there",
      "Because the nav has disappear ago, it was the keyword for all the regular expression and execution of a router",
      "Now you know, over that, in there",
      "Let's do that there we already got ourselves some of the way to go",
      "And it matches the new one for that",
      "Yeah",
      "And certain time gaps, think it's an animating to put a route for some reason",
      "view",
      "Figure out things simplicity at this point",
      "So what we're being a little bit of a pickle over right now we've deep",
      "linked that could want it to be that",
      "So let's just feels very interactions back in so this",
      "newView",
      "Yeah",
      "And apparent, what we'd want each one of all the debugger standard one",
      "So this way, it should add the visible",
      "And we're pretty raw, there will be find out notionally, the code, it's fine, it's fail",
      "So the question",
      "Yeah, so we could see now them to makes Jav",
    ]
  }
  
  export default data